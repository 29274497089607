import React from "react"

import Layout from "../components/layout"
import SeoComp from "../components/seo"
import { graphql } from "gatsby"

const NotFoundPage = ({ data }) => {
  const global = data.strapiGlobal

  const {
    site: {
      siteMetadata: {
        languages: { locales, defaultLocale },
      },
    },
  } = data

  return (
    <>
      <SeoComp seo={{ title: "404: Not found" }} global={global} />
      <Layout global={global} pageContext={{
          locale: defaultLocale,
          locales,
          defaultLocale,
          slug: "404",
        }}>
        <section class="container">
				  <h1 class="section-title text-center lines">Page not found</h1>
        </section>
      </Layout>
    </>
  )
}

export default NotFoundPage

export const query = graphql`
  query NotFoundQuery {
    strapiGlobal(locale: { eq: "en" }) {
      ...GlobalData
    }
    site {
      siteMetadata {
        languages {
          locales
          defaultLocale
        }
      }
    }
  }
`
